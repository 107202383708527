





















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {
    ArkStatsBackendClient,
    ResourceListDto,
    TribeDto,
    TribeLogDto
} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import {ArkServerManagerClient, ServerDto} from '@/logic/ArkServerManagerClient';
import moment from 'moment';
import DisplayName from '@/components/DisplayName.vue';
import PlayerName from '@/components/PlayerName.vue';

Vue.use(VueAsyncComputed);

@Component({
    components: {DisplayName, PlayerName}
})
export default class TribeById extends Vue {
    @Prop({default: ''}) private id!: string;
    serverId: number | null = null;
    tribeId: number | null = null;
    errorNoSuchTribe = false;
    errorRetrievingServer = false;
    errorRetrievingTribeLog = false;

    public serverUrl(serverId: number): string {
        return `https://ark.domination-gaming.com/server/${serverId}`;
    }

    public tribeUrl(serverId: number, tribeId: number): string {
        return `https://ark.domination-gaming.com/server/${serverId}/tribe/${tribeId}`;
    }

    public tribeUrlArkStats(serverId: number, tribeId: number): string {
        return `/tribe/${serverId}-${tribeId}`;
    }

    public survivorUrl(serverId: number, survivorId: number): string {
        return `https://ark.domination-gaming.com/server/${serverId}/survivor/${survivorId}`;
    }

    public dinosUrl(serverId: number, tribeId: number): string {
        return `/dinos?preFilter=serverId==${serverId};owner.tribeId==${tribeId};died==null`;
    }

    @AsyncComputedProp()
    public async tribe(): Promise<TribeDto> {
        const tribe = (await ArkStatsBackendClient.getTribes({
            filter: 'id==' + this.id,
            limit: 1
        })).data[0];
        if (tribe) {
            this.serverId = tribe.serverId || null;
            this.tribeId = tribe.tribeId || null;
        } else {
            this.errorNoSuchTribe = true;
        }
        return tribe;
    }

    @AsyncComputedProp()
    public async server(): Promise<ServerDto | null> {
        if (this.serverId) {
            try {
                return await ArkServerManagerClient.getServer(this.serverId);
            } catch (ex) {
                this.errorRetrievingServer = true;
            }
        }
        return null;
    }

    @AsyncComputedProp()
    public async tribeLogs(): Promise<ResourceListDto<TribeLogDto> | null> {
        if (this.serverId && this.tribeId) {
            try {
                return await ArkStatsBackendClient.getTribeLogs({
                    filter: `tribeId==${this.tribeId};serverId==${this.serverId}`,
                    orderBy: `-day,-time`
                });
            } catch (ex) {
                this.errorRetrievingTribeLog = true;
            }
        }
        return null;
    }

    public localDate(timestamp: string): string {
        return moment(timestamp).local().format('MMM D YYYY, HH:mm');
    }

    public stripColors(message: string): string {
        return !message ? '' : message
            .replace('<RichColor Color="1, 0, 0, 1">', '')
            .replace('<RichColor Color="0, 1, 0, 1">', '')
            .replace('<RichColor Color="0, 0, 1, 1">', '')
            .replace('<RichColor Color="1, 1, 0, 1">', '')
            .replace('<RichColor Color="0, 1, 1, 1">', '')
            .replace('<RichColor Color="1, 0, 1, 1">', '')
            .replace('</>', '');
    }

    public extractColor(message: string): string {
        if (!message) return 'msg-black';
        if (message.indexOf('Color="1, 0, 0, 1"') >= 0) return 'msg-red';
        if (message.indexOf('Color="0, 1, 0, 1"') >= 0) return 'msg-green';
        if (message.indexOf('Color="0, 0, 1, 1"') >= 0) return 'msg-blue';
        if (message.indexOf('Color="1, 1, 0, 1"') >= 0) return 'msg-yellow';
        if (message.indexOf('Color="1, 0, 1, 1"') >= 0) return 'msg-purple';
        if (message.indexOf('Color="0, 1, 1, 1"') >= 0) return 'msg-cyan';
        return 'msg-black';
    }
}

